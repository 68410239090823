// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bonus-code-input__link_oyE7Z{z-index:1;padding-right:3px;color:var(--TextPrimary);-webkit-text-decoration:underline;text-decoration:underline;cursor:pointer}.bonus-code-input__link_oyE7Z:hover{color:var(--BrandHighlight)}.bonus-code-input__link-comment_J15av{color:var(--TextSecondary)}.bonus-code-input__wrapper_K54rj{font-size:13px;font-weight:400;line-height:16px;letter-spacing:normal;position:relative;display:flex;align-items:center;min-height:44px;cursor:pointer}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"bonus-code-input__link": `bonus-code-input__link_oyE7Z`,
	"bonus-code-input__link-comment": `bonus-code-input__link-comment_J15av`,
	"bonus-code-input__wrapper": `bonus-code-input__wrapper_K54rj`
};
export default ___CSS_LOADER_EXPORT___;
