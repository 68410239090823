import { logger } from '@leon-hub/logging';
import { isDebugEnabled } from '@leon-hub/debug';
import { useRootStore } from 'web/src/modules/core/store';
export default function initLogger() {
    const rootStore = useRootStore();
    const version = "6.102.0";
    const name = "leon-front";
    logger.init({
        appName: name,
        appVersion: version,
        remoteSaveAction: rootStore.log,
        isConsoleOutputAllowed: ()=>rootStore.isDevIP && !isDebugEnabled()
    });
}
