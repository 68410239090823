// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.timer__wrapper_NPU9H{width:18px;height:18px;transform:scaleX(-1)}.timer__wrapper--size-small_Nk6vM{width:16px;height:16px}.timer__counter_Ypn0X{fill:#0000;stroke:var(--TextSecondary);stroke-width:10;transition:stroke-dasharray 1s,stroke 1s;transition-timing-function:linear}.timer__counter--danger_hdONq{stroke:var(--ErrorText)}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"timer__wrapper": `timer__wrapper_NPU9H`,
	"timer__wrapper--size-small": `timer__wrapper--size-small_Nk6vM`,
	"timer__counter": `timer__counter_Ypn0X`,
	"timer__counter--danger": `timer__counter--danger_hdONq`
};
export default ___CSS_LOADER_EXPORT___;
