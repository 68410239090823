// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.column__left_aiVpX{display:flex;flex-direction:column;flex-shrink:0;order:1;min-width:252px;max-width:252px;overflow-x:hidden;background-color:var(--BodyBackgroundColor)}.column__left_aiVpX::-webkit-scrollbar{display:none}.column__left--open_hK6Rd{z-index:65;overflow-x:unset}.column__left_aiVpX .content_t6uHY{padding:0}.column__left-close_WW7yW{position:absolute;top:0;right:-40px;width:40px;height:40px}.column__main-mask_zYxQP{position:absolute;top:56px;right:336px;bottom:0;left:0;z-index:64;min-width:688px;content:"";background-color:#0009}@media(max-width:1150px){.column__left_aiVpX{position:absolute;top:56px;left:0;z-index:65;height:100%;overflow-y:visible;transition:transform .15s ease-in-out;transform:translateX(calc(-100% - 16px))}.column__left--open_hK6Rd{transform:translateX(0)}}.left-side-bar__content_RVo37{padding:8px 16px 40px}.opacity-enter-active_yDHZM,.opacity-leave-active_rXZEO{transition:opacity .25s ease-in-out}.opacity-enter-from_U2upx,.opacity-leave-to_NLGEF{opacity:1}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"column__left": `column__left_aiVpX`,
	"column__left--open": `column__left--open_hK6Rd`,
	"content": `content_t6uHY`,
	"column__left-close": `column__left-close_WW7yW`,
	"column__main-mask": `column__main-mask_zYxQP`,
	"left-side-bar__content": `left-side-bar__content_RVo37`,
	"opacity-enter-active": `opacity-enter-active_yDHZM`,
	"opacity-leave-active": `opacity-leave-active_rXZEO`,
	"opacity-enter-from": `opacity-enter-from_U2upx`,
	"opacity-leave-to": `opacity-leave-to_NLGEF`
};
export default ___CSS_LOADER_EXPORT___;
