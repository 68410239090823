import { isFunction } from '@leon-hub/guards';
import { Deferred } from '@leon-hub/utils';
import { importComponent } from 'web/src/modules/core/utils';
import AbstractPrefetch from '../prefetch/AbstractPrefetch';
function isPrefetchFunction(value) {
    return isFunction(value);
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default async function prefetchRouteComponents(to, from, next, router) {
    if (!to.meta?.prefetch) {
        next();
        return;
    }
    const { prefetch } = to.meta;
    isPrefetchFunction(prefetch);
    const AbstractPrefetchClass = await importComponent(prefetch);
    const newClass = new AbstractPrefetchClass();
    if (!(newClass instanceof AbstractPrefetch)) throw new Error('Imported prefetch is not of AbstractPrefetch class');
    const deferred = new Deferred();
    await newClass.prefetch(router, to, from, (result)=>{
        deferred.resolve(result);
    });
    const resolvedPromise = await deferred.promise;
    next(resolvedPromise);
}
