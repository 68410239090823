import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, createBlock as _createBlock, KeepAlive as _KeepAlive, mergeProps as _mergeProps, withCtx as _withCtx, normalizeStyle as _normalizeStyle, Fragment as _Fragment } from "vue";
import { defineAsyncComponent } from 'vue';
import { IconName } from '@leon-hub/icons';
import RouteName from '@leon-hub/routing-config-names';
import { VScrollbar } from '@components/v-scrollbar';
import VOverlay from 'web/src/components/Modal/VOverlay/VOverlay.vue';
import { ModalHeight, ModalWidth } from 'web/src/modules/core/components/DesktopModal/enums';
import { ModalDesktopPreset } from 'web/src/modules/core/enums';
import SButton from 'web/src/components/Button/SButton/SButton.vue';
import useDesktopModalHeight from 'web/src/modules/core/components/DesktopModal/composables/useDesktopModalHeight';
import { useDesktopModal, useDesktopModalComponents, useDesktopModalScroll } from './composables';
import { useKeepAliveComponents } from '../composables/useKeepAliveComponents';
export default /*@__PURE__*/ _defineComponent({
    __name: 'DesktopModal',
    setup (__props) {
        const LeftSideBar = defineAsyncComponent(()=>import('web/src/modules/profile/layouts/UserProfileSidebarRouteComponent/UserProfileSidebarRouteComponent.vue'));
        const LeftSideTopBar = defineAsyncComponent(()=>import('web/src/modules/profile/components/SidebarProfileInfo/SidebarProfileInfoRouteComponent.vue'));
        const { isNoMinHeightLimit, desktopModal, modalWidth, modalHeight, modalPreset, hasInnerModal, showSideBar, currentRouteName, onOverlayClicked, onInnerScroll } = useDesktopModal();
        const { DefaultComponent, Navigation, defaultProps, TopBar, topBarProps } = useDesktopModalComponents();
        const { scrollbar, recomposeScroll } = useDesktopModalScroll();
        const { sidebar, contentStyles } = useDesktopModalHeight();
        const { keepAliveIncludes } = useKeepAliveComponents();
        return (_ctx, _cache)=>{
            const _directive_data_test = _resolveDirective("data-test");
            return _openBlock(), _createElementBlock(_Fragment, null, [
                _unref(desktopModal) ? _withDirectives((_openBlock(), _createElementBlock("div", {
                    key: 0,
                    id: "outerModalContainer",
                    class: _normalizeClass(_ctx.$style['desktop-modal__wrapper'])
                }, [
                    _createElementVNode("div", {
                        class: _normalizeClass(_ctx.$style['desktop-modal__radius'])
                    }, [
                        _createElementVNode("div", {
                            class: _normalizeClass({
                                [_ctx.$style['desktop-modal']]: true,
                                [_ctx.$style['desktop-modal--no-min-height']]: _unref(isNoMinHeightLimit),
                                [_ctx.$style["desktop-modal--width--responsible"]]: void 0,
                                [_ctx.$style[`desktop-modal--width-${_unref(modalWidth)}`]]: _unref(modalWidth) !== _unref(ModalWidth).DEFAULT && true,
                                [_ctx.$style[`desktop-modal--height-${_unref(modalHeight)}`]]: _unref(modalHeight) !== _unref(ModalHeight).DEFAULT,
                                [_ctx.$style['desktop-modal--profile']]: _unref(modalPreset) === _unref(ModalDesktopPreset).ProfileDesktopModal,
                                [_ctx.$style['desktop-modal--with-inner-modal']]: _unref(hasInnerModal)
                            })
                        }, [
                            _unref(showSideBar) ? _withDirectives((_openBlock(), _createElementBlock("div", {
                                key: 0,
                                class: _normalizeClass(_ctx.$style['desktop-modal__sidebar'])
                            }, [
                                _createElementVNode("div", {
                                    ref_key: "sidebar",
                                    ref: sidebar
                                }, [
                                    _createVNode(_unref(LeftSideTopBar)),
                                    _createVNode(_unref(LeftSideBar))
                                ], 512)
                            ], 2)), [
                                [
                                    _directive_data_test,
                                    {
                                        el: 'desktop-modal-sidebar'
                                    }
                                ]
                            ]) : _createCommentVNode("", true),
                            _createElementVNode("div", {
                                id: "desktop-modal",
                                class: _normalizeClass(_ctx.$style['desktop-modal__content']),
                                style: _normalizeStyle(_unref(contentStyles))
                            }, [
                                (_openBlock(), _createBlock(_KeepAlive, {
                                    include: _unref(keepAliveIncludes)
                                }, [
                                    (_openBlock(), _createBlock(_resolveDynamicComponent(_unref(TopBar)), _normalizeProps(_guardReactiveProps(_unref(topBarProps))), null, 16))
                                ], 1032, [
                                    "include"
                                ])),
                                _createElementVNode("div", {
                                    class: _normalizeClass({
                                        [_ctx.$style['desktop-modal__scrollbar']]: true,
                                        [_ctx.$style['desktop-modal__scrollbar--profile']]: _unref(currentRouteName) === _unref(RouteName).PROFILE
                                    })
                                }, [
                                    _createVNode(_unref(VScrollbar), {
                                        ref_key: "scrollbar",
                                        ref: scrollbar,
                                        "flex-fill": "",
                                        "test-el": "modal",
                                        "use-scroll-listener": "",
                                        onScroll: _unref(onInnerScroll)
                                    }, {
                                        default: _withCtx(()=>[
                                                (_openBlock(), _createBlock(_KeepAlive, {
                                                    include: _unref(keepAliveIncludes)
                                                }, [
                                                    _unref(Navigation) ? (_openBlock(), _createBlock(_resolveDynamicComponent(_unref(Navigation)), {
                                                        key: 0,
                                                        onVnodeMounted: _unref(recomposeScroll)
                                                    }, null, 8, [
                                                        "onVnodeMounted"
                                                    ])) : _createCommentVNode("", true)
                                                ], 1032, [
                                                    "include"
                                                ])),
                                                (_openBlock(), _createBlock(_KeepAlive, {
                                                    include: _unref(keepAliveIncludes)
                                                }, [
                                                    (_openBlock(), _createBlock(_resolveDynamicComponent(_unref(DefaultComponent)), _mergeProps(_unref(defaultProps), {
                                                        onVnodeMounted: _unref(recomposeScroll)
                                                    }), null, 16, [
                                                        "onVnodeMounted"
                                                    ]))
                                                ], 1032, [
                                                    "include"
                                                ]))
                                            ]),
                                        _: 1
                                    }, 8, [
                                        "onScroll"
                                    ])
                                ], 2)
                            ], 6)
                        ], 2),
                        _createCommentVNode("", true)
                    ], 2)
                ], 2)), [
                    [
                        _directive_data_test,
                        {
                            el: 'desktop-modal-wrapper',
                            route: _unref(currentRouteName)
                        }
                    ]
                ]) : _createCommentVNode("", true),
                _unref(desktopModal) ? (_openBlock(), _createBlock(VOverlay, {
                    key: 1,
                    class: _normalizeClass(_ctx.$style['desktop-modal__overlay']),
                    onClick: _unref(onOverlayClicked)
                }, null, 8, [
                    "class",
                    "onClick"
                ])) : _createCommentVNode("", true)
            ], 64);
        };
    }
});
