export const CasinoRouteName = {
    CASINO_LOBBY: 'casino-lobby',
    CASINO_GROUPS: 'casino-groups',
    CASINO_GAME: 'casino-game',
    CASINO_LIVE_LOBBY: 'casino-live-lobby',
    CASINO_LIVE_GROUPS: 'casino-live-groups',
    CASINO_LIVE_GAME: 'casino-live-game-v2',
    CASINO_SEARCH: 'casino-search',
    CASINO_BETGAMES: 'casino-betgames',
    CASINO_LOYALTY_PROGRAM: 'casino-loyalty-program',
    CASINO_LOYALTY_PROGRAM_ID: 'casino-loyalty-program-id',
    CASINO_LOYALTY_PROGRAM_INFO: 'casino-loyalty-program-info',
    GAME_EXIT: 'game-exit',
    WEB2_CASINO_RULES: 'WEB2_CASINO_RULES',
    CASINO_MY_GAMES: 'casino-my-games'
};
export default CasinoRouteName;
const casinoRouteNames = Object.values(CasinoRouteName);
export function isCasinoRouteName(value) {
    return casinoRouteNames.includes(value);
}
