// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.linear-loader_yIlHu{margin:0 auto;color:var(--White)}.linear-loader--with-hint_r613j{width:auto;height:auto;padding:0}.linear-loader__hint_jySLC{font-size:14px;font-weight:400;line-height:24px;letter-spacing:.25px;display:block;margin-top:0;margin-bottom:13px;text-align:center}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"linear-loader": `linear-loader_yIlHu`,
	"linear-loader--with-hint": `linear-loader--with-hint_r613j`,
	"linear-loader__hint": `linear-loader__hint_jySLC`
};
export default ___CSS_LOADER_EXPORT___;
