// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.drag-and-drop-input_K1Gpm{display:grid;grid-template-columns:repeat(auto-fit,164px);row-gap:12px;column-gap:16px;padding:30px 20px;margin-top:20px;border:1px dashed var(--Highlight);border-radius:5px}.drag-and-drop-input--empty_xrm9X{display:flex;justify-content:center}.drag-and-drop-input--error_RSAN4{border-color:var(--ErrorText)}.drag-and-drop-input--drag-over_ldPM_{position:relative;border-color:var(--BrandDefault)}.drag-and-drop-input__drag-zone_MCjZt{position:absolute;top:0;left:0;display:none;width:100%;height:100%}.drag-and-drop-input--drag-over_ldPM_ .drag-and-drop-input__drag-zone_MCjZt{display:block}.drag-and-drop-input__item_aRklq{width:164px;overflow:hidden}.drag-and-drop-input__item--placeholder_vUQ3I{max-height:180px;background:var(--Layer1)}.drag-and-drop-input__item--controls_mHgiw{display:flex;flex-direction:column;align-items:center;justify-content:space-between;min-height:180px}.drag-and-drop-input__text-primary_YiAbu{text-transform:uppercase;color:var(--TextDefault)}.drag-and-drop-input__text-primary_YiAbu,.drag-and-drop-input__text-secondary_jv1m1{font-size:14px;font-weight:500;line-height:16px;letter-spacing:.25px;text-align:center}.drag-and-drop-input__text-secondary_jv1m1{color:var(--TextSecondary)}.drag-and-drop-input__add-button_r19jD{flex-shrink:0}.drag-and-drop-input__input_auv2I{display:none}.drag-and-drop-input__error_mMcCg{margin-bottom:8px}.drag-and-drop-input__error_mMcCg:first-letter{text-transform:none}.drag-and-drop-input__error_mMcCg:last-child{margin-bottom:0}.drag-and-drop-input__error_mMcCg:first-child{margin-top:4px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"drag-and-drop-input": `drag-and-drop-input_K1Gpm`,
	"drag-and-drop-input--empty": `drag-and-drop-input--empty_xrm9X`,
	"drag-and-drop-input--error": `drag-and-drop-input--error_RSAN4`,
	"drag-and-drop-input--drag-over": `drag-and-drop-input--drag-over_ldPM_`,
	"drag-and-drop-input__drag-zone": `drag-and-drop-input__drag-zone_MCjZt`,
	"drag-and-drop-input__item": `drag-and-drop-input__item_aRklq`,
	"drag-and-drop-input__item--placeholder": `drag-and-drop-input__item--placeholder_vUQ3I`,
	"drag-and-drop-input__item--controls": `drag-and-drop-input__item--controls_mHgiw`,
	"drag-and-drop-input__text-primary": `drag-and-drop-input__text-primary_YiAbu`,
	"drag-and-drop-input__text-secondary": `drag-and-drop-input__text-secondary_jv1m1`,
	"drag-and-drop-input__add-button": `drag-and-drop-input__add-button_r19jD`,
	"drag-and-drop-input__input": `drag-and-drop-input__input_auv2I`,
	"drag-and-drop-input__error": `drag-and-drop-input__error_mMcCg`
};
export default ___CSS_LOADER_EXPORT___;
