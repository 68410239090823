// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.jumbotron-footer-button_RbShZ{margin-right:auto;margin-left:auto}.jumbotron-footer-button--full-width_OWbBT{width:100%}.jumbotron-footer-button--full-width_OWbBT+.jumbotron-footer-button--full-width_OWbBT{margin-top:20px;margin-left:0}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"jumbotron-footer-button": `jumbotron-footer-button_RbShZ`,
	"jumbotron-footer-button--full-width": `jumbotron-footer-button--full-width_OWbBT`
};
export default ___CSS_LOADER_EXPORT___;
