// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@keyframes dot-color-change-1_qDej4{0%{opacity:.3}28%{opacity:1}to{opacity:1}}@keyframes dot-color-change-2_fXK7R{0%{opacity:.3}28%{opacity:.3}56%{opacity:1}to{opacity:1}}@keyframes dot-color-change-3_o9JOh{0%{opacity:.3}28%{opacity:.3}56%{opacity:.3}84%{opacity:1}to{opacity:1}}.dotted-loader_eznFV{display:inline;width:100%;text-align:center}.dotted-loader__dot_T7n9n{display:inline-block;width:4px;height:4px;margin-right:4px;background-color:var(--TextSecondary);border-radius:50%;animation:dot-color-change-1_qDej4 2s ease-in infinite}.dotted-loader--primary_ip0mJ .dotted-loader__dot_T7n9n{background-color:var(--TextDefault)}.dotted-loader__dot--second_f7N7A{animation:dot-color-change-2_fXK7R 2s ease-in infinite}.dotted-loader__dot--third_g9CiI{margin-right:0;animation:dot-color-change-3_o9JOh 2s ease-in infinite}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dotted-loader": `dotted-loader_eznFV`,
	"dotted-loader__dot": `dotted-loader__dot_T7n9n`,
	"dot-color-change-1": `dot-color-change-1_qDej4`,
	"dotted-loader--primary": `dotted-loader--primary_ip0mJ`,
	"dotted-loader__dot--second": `dotted-loader__dot--second_f7N7A`,
	"dot-color-change-2": `dot-color-change-2_fXK7R`,
	"dotted-loader__dot--third": `dotted-loader__dot--third_g9CiI`,
	"dot-color-change-3": `dot-color-change-3_o9JOh`
};
export default ___CSS_LOADER_EXPORT___;
