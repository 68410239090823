import { watch } from 'vue';
import { useIsLoggedIn } from 'web/src/modules/auth/composables';
import { runDeviceIdRoutine } from 'web/src/modules/auth/utils';
import { startLoginDeviceRoutineEventType, useAppEmitter } from 'web/src/modules/emitter';
export const setupEmitter = ()=>{
    useAppEmitter().on(startLoginDeviceRoutineEventType, ()=>{
        const { isLoggedIn } = useIsLoggedIn();
        if (isLoggedIn.value) runDeviceIdRoutine();
        else watch(isLoggedIn, ()=>{
            runDeviceIdRoutine();
        }, {
            once: true
        });
    });
};
