import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue";
import { defineAsyncComponent } from 'vue';
import { useIsLoggedIn } from 'web/src/modules/auth/composables';
import { getAsyncComponent } from 'web/src/modules/core/utils';
import CordovaDialogsController from 'web/src/modules/cordova/components/CordovaDialogsController/CordovaDialogsController.vue';
import DiagnosticsController from 'web/src/modules/core/components/DiagnosticsController/DiagnosticsController.vue';
import { useMainApp } from './composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'MainApp',
    setup (__props) {
        const PostponedAppController = defineAsyncComponent(()=>import('web/src/modules/core/components/PostponedAppController/PostponedAppController.vue'));
        const AuthorizedAppController = defineAsyncComponent(()=>import('web/src/modules/core/components/AuthorizedAppController/AuthorizedAppController.vue'));
        const GuestAppController = getAsyncComponent(()=>import('web/src/modules/core/components/GuestAppController/GuestAppController.vue'));
        const TheMetaInfo = defineAsyncComponent(()=>import('web/src/modules/seo/components/TheMetaInfo.vue'));
        const TheModal = defineAsyncComponent(()=>import('web/src/modules/dialogs/views/TheModal/TheModal.vue'));
        const { isLoggedIn } = useIsLoggedIn();
        const { errorComponent, mainComponent, isPostponedControllerVisible, isAppInit, onAppControllerError, onAppControllerLoaded } = useMainApp();
        return (_ctx, _cache)=>(_openBlock(), _createElementBlock(_Fragment, null, [
                _unref(errorComponent) ? (_openBlock(), _createBlock(_resolveDynamicComponent(_unref(errorComponent)), {
                    key: 0
                })) : (_openBlock(), _createElementBlock(_Fragment, {
                    key: 1
                }, [
                    (_openBlock(), _createBlock(_resolveDynamicComponent(_unref(mainComponent)))),
                    _createVNode(_unref(TheModal)),
                    _unref(isPostponedControllerVisible) ? (_openBlock(), _createBlock(_unref(PostponedAppController), {
                        key: 0
                    })) : _createCommentVNode("", true),
                    _unref(isLoggedIn) ? (_openBlock(), _createBlock(_unref(AuthorizedAppController), {
                        key: 1,
                        "is-app-init": _unref(isAppInit),
                        onLoadError: _unref(onAppControllerError),
                        onLoaded: _unref(onAppControllerLoaded)
                    }, null, 8, [
                        "is-app-init",
                        "onLoadError",
                        "onLoaded"
                    ])) : (_openBlock(), _createBlock(_unref(GuestAppController), {
                        key: 2,
                        "is-app-init": _unref(isAppInit),
                        onLoadError: _unref(onAppControllerError),
                        onLoaded: _unref(onAppControllerLoaded)
                    }, null, 8, [
                        "is-app-init",
                        "onLoadError",
                        "onLoaded"
                    ]))
                ], 64)),
                _createVNode(_unref(TheMetaInfo)),
                _createCommentVNode("", true),
                _createCommentVNode("", true)
            ], 64));
    }
});
