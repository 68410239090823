// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.desktop-modal_hkGYs{z-index:1;width:375px;display:flex;min-height:0;max-height:calc(100vh - 92px);overflow:hidden;pointer-events:all;border-radius:15px;box-shadow:0 8px 16px var(--ModalShadow);will-change:transform}.desktop-modal--no-min-height_xs4qQ{min-height:40px}.desktop-modal__radius_DihbI{position:relative;display:flex;border-radius:15px}.desktop-modal__sidebar_kLYKj{min-width:252px;max-width:252px;background-color:var(--Layer0)}.desktop-modal__sidebar_kLYKj+.desktop-modal__content_YLYtH{max-width:calc(100% - 252px)}.desktop-modal__content_YLYtH{position:relative;display:flex;flex-direction:column;width:100%}.desktop-modal__scrollbar_UtatO{display:flex;flex:1;flex-direction:column;overflow:auto;background-color:var(--Layer0)}.desktop-modal__wrapper_tNgxg{z-index:69;position:fixed;top:0;left:0;display:flex;flex-direction:column;align-items:center;width:100%;height:100%;padding-top:56px;pointer-events:none}.desktop-modal--width-medium_ZipW2{width:480px}.desktop-modal--width-medium-plus_PHEoC{width:540px}.desktop-modal--width-big_Rowkh{width:627px}.desktop-modal--width-large_I5p2d{width:720px}.desktop-modal--height-large_fYFAB{height:600px}.desktop-modal--height-extra__5xiL{height:584px}.desktop-modal--with-inner-modal_xP8lM{min-height:396px}.desktop-modal__overlay_NIYMd{z-index:68}.desktop-modal__close_nkyCe{position:absolute;bottom:100%;left:100%;z-index:1;pointer-events:all}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"desktop-modal": `desktop-modal_hkGYs`,
	"desktop-modal--no-min-height": `desktop-modal--no-min-height_xs4qQ`,
	"desktop-modal__radius": `desktop-modal__radius_DihbI`,
	"desktop-modal__sidebar": `desktop-modal__sidebar_kLYKj`,
	"desktop-modal__content": `desktop-modal__content_YLYtH`,
	"desktop-modal__scrollbar": `desktop-modal__scrollbar_UtatO`,
	"desktop-modal__wrapper": `desktop-modal__wrapper_tNgxg`,
	"desktop-modal--width-medium": `desktop-modal--width-medium_ZipW2`,
	"desktop-modal--width-medium-plus": `desktop-modal--width-medium-plus_PHEoC`,
	"desktop-modal--width-big": `desktop-modal--width-big_Rowkh`,
	"desktop-modal--width-large": `desktop-modal--width-large_I5p2d`,
	"desktop-modal--height-large": `desktop-modal--height-large_fYFAB`,
	"desktop-modal--height-extra": `desktop-modal--height-extra__5xiL`,
	"desktop-modal--with-inner-modal": `desktop-modal--with-inner-modal_xP8lM`,
	"desktop-modal__overlay": `desktop-modal__overlay_NIYMd`,
	"desktop-modal__close": `desktop-modal__close_nkyCe`
};
export default ___CSS_LOADER_EXPORT___;
