import { createPropsRestProxy as _createPropsRestProxy, defineComponent as _defineComponent } from 'vue';
import { unref as _unref, renderSlot as _renderSlot, mergeProps as _mergeProps, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue";
import { ButtonHeight } from '@leon-hub/module-buttons';
import VButton from 'web/src/components/Button/VButton/VButton.vue';
import { isSButtonProps, isVButtonProps } from 'web/src/components/Button/SButton/types';
import SButton from 'web/src/components/Button/SButton/SButton.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'JumbotronFooterButton',
    props: {
        rounded: {
            type: Boolean,
            default: false
        },
        height: {
            default: ()=>ButtonHeight.LARGE
        },
        textColor: {},
        isUppercase: {
            type: Boolean,
            default: true
        },
        iconSize: {},
        hasAnimation: {
            type: Boolean,
            default: true
        },
        isAbsolutePosition: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean
        },
        for: {},
        fullWidth: {
            type: Boolean
        },
        href: {},
        iconName: {},
        iconRight: {
            type: Boolean
        },
        isAutofocus: {
            type: Boolean
        },
        isLoading: {
            type: Boolean
        },
        isNoPaddings: {
            type: Boolean
        },
        label: {},
        role: {},
        tag: {},
        target: {},
        type: {},
        id: {},
        kind: {},
        action: {}
    },
    emits: [
        "click",
        "keydown",
        "mousedown",
        "focus",
        "blur"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = _createPropsRestProxy(__props, [
            "isUppercase",
            "hasAnimation",
            "isAbsolutePosition",
            "rounded",
            "height",
            "action"
        ]);
        const emit = __emit;
        return (_ctx, _cache)=>_unref(isVButtonProps)(props) ? (_openBlock(), _createBlock(VButton, _mergeProps({
                key: 1
            }, props, {
                action: _ctx.action,
                height: _ctx.height,
                "is-uppercase": _ctx.isUppercase,
                "has-animation": _ctx.hasAnimation,
                "is-absolute-position": _ctx.isAbsolutePosition,
                rounded: _ctx.rounded,
                class: {
                    [_ctx.$style['jumbotron-footer-button']]: true,
                    [_ctx.$style['jumbotron-footer-button--full-width']]: props.fullWidth
                },
                onClick: _cache[3] || (_cache[3] = ($event)=>emit('click', $event)),
                onKeydown: _cache[4] || (_cache[4] = ($event)=>emit('keydown', $event)),
                onMousedown: _cache[5] || (_cache[5] = ($event)=>emit('mousedown', $event))
            }), {
                default: _withCtx(()=>[
                        _renderSlot(_ctx.$slots, "default")
                    ]),
                _: 3
            }, 16, [
                "action",
                "height",
                "is-uppercase",
                "has-animation",
                "is-absolute-position",
                "rounded",
                "class"
            ])) : _createCommentVNode("", true);
    }
});
