// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.desktop-title_fv57A{display:flex;padding:24px 0 16px}.desktop-title__title_LWqks{font-size:18px;font-weight:500;line-height:20px;letter-spacing:normal;color:var(--TextDefault);padding:0;margin:0}.desktop-title__title--big_OALnA{font-size:24px;font-weight:500;line-height:28px;letter-spacing:normal}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"desktop-title": `desktop-title_fv57A`,
	"desktop-title__title": `desktop-title__title_LWqks`,
	"desktop-title__title--big": `desktop-title__title--big_OALnA`
};
export default ___CSS_LOADER_EXPORT___;
