import { EditionKey } from '@leon-hub/environment-editions';
import { useRootStore } from 'web/src/modules/core/store';
export function useConditionValue(name) {
    switch(name){
        case EditionKey.PRODUCT:
            return "li";
        case EditionKey.RENDERING:
            return "csr";
        case 'version':
            return "6.102.0";
        case EditionKey.PLATFORM:
            return "web";
        case EditionKey.MODERNITY:
            return "modern";
        case EditionKey.ENV:
            return "prod";
        case EditionKey.SKIN:
            return "default";
        case EditionKey.STYLE:
            return "leon";
        case EditionKey.OS:
            return process.env.VUE_APP_OS;
        case EditionKey.LAYOUT:
            return "desktop";
        case EditionKey.BROWSER:
            return process.env.VUE_APP_BROWSER;
        case EditionKey.THEME:
            return process.env.VUE_APP_THEME;
        case 'directLink':
            return useRootStore().isDirectLink ? 'true' : void 0;
        case 'inFrame':
            try {
                if (window.self !== window.top) return '1';
            } catch  {}
            // empty
            return;
        default:
            throw new Error(`Undefined env variable: ${name}`);
    }
}
