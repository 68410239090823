import { AppModuleApiProvider } from '@leon-hub/app-module';
import { logger } from '@leon-hub/logging';
import configureVueApp from 'web/src/core/app/vue/configureVueApp';
import { useActivityReporter } from 'web/src/modules/activity-reporter/useActivityReporter';
import { onAppMounted } from 'web/src/modules/auth/utils/onAppMounted';
import { bootstrapIcons } from 'web/src/modules/core/apps/main/bootstrapIcons';
import { consumePrefetch } from 'web/src/modules/core/apps/main/prefetch/consumePrefetch';
import { setupEmitter } from 'web/src/modules/core/apps/main/setupEmitter';
import { createSimpleApp } from 'web/src/modules/core/utils';
import { getErrorAppComponentByError } from 'web/src/modules/errors/utils';
import { createAppRouter } from 'web/src/modules/core/services/router';
import initPublicAppInterface from 'web/src/plugins/AppPlugins/initPublicAppInterface';
import { useErrorsConverter } from 'web/src/modules/errors/composables';
import { getAppModulePlugin } from 'web/src/modules/core/plugins';
import { useFontsStore } from 'web/src/modules/core/store/useFontsStore';
import 'web/src/assets/sass/mainApp.scss';
import { initLogger, forcePhoneLayout, initStores, startSentry, setupAppBusEvents, getAppModules, setupRouterLoadedEvents } from './utils';
import { startAppSw } from './startAppSw';
// eslint-disable-next-line sonarjs/cognitive-complexity
export default async function startApp(app, prefetchProducer) {
    try {
        useFontsStore().loadFonts();
        await consumePrefetch(await prefetchProducer);
        setupEmitter();
        configureVueApp(app);
        initLogger();
        if (!process.env.VUE_APP_PRERENDER) forcePhoneLayout().catch((err)=>logger.error('Unable sync layout env', err));
        initStores();
        const router = createAppRouter();
        app.use(getAppModulePlugin(), {
            router,
            modules: getAppModules()
        });
        startSentry(app, router);
        setupAppBusEvents(router);
        initPublicAppInterface(router);
        if (!process.env.VUE_APP_PRERENDER) startAppSw();
        bootstrapIcons();
        AppModuleApiProvider.reset();
        useActivityReporter().start();
        await setupRouterLoadedEvents(router);
        app.mount('#app');
        onAppMounted();
    } catch (rawError) {
        const error = useErrorsConverter().convertToBaseError(rawError);
        logger.error('Start App Error', error);
        if (process.env.VUE_APP_PRERENDER) // eslint-disable-next-line no-console
        console.info('Error JSON', error.toJSON());
        // eslint-disable-next-line no-console
        try {
            // In case of main app was already mounted on #app element.
            // Sentry shows errors within "simple-app" mount process.
            app.unmount();
        } catch  {}
        createSimpleApp(getErrorAppComponentByError(rawError));
        bootstrapIcons();
        throw rawError;
    }
}
