import { defineComponent as _defineComponent } from 'vue';
import { createVNode as _createVNode, withCtx as _withCtx, unref as _unref, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, KeepAlive as _KeepAlive, resolveDirective as _resolveDirective, Teleport as _Teleport, Fragment as _Fragment } from "vue";
import { defineAsyncComponent } from 'vue';
import HeaderBarRouteComponent from 'web/src/modules/core/views/HeaderBarRouteComponent/HeaderBarRouteComponent.vue';
import FooterRouteComponent from 'web/src/modules/core/views/FooterRouteComponent/FooterRouteComponent.vue';
import { LazyMainBannerSectionRouteComponent } from 'web/src/modules/banners/components/MainBannerSectionRouteComponent';
import DesktopModal from 'web/src/modules/core/components/DesktopModal/DesktopModal.vue';
import vDataTestUnit from 'web/src/testing/directives/vDataTestUnit';
import { useIsLoggedIn } from 'web/src/modules/auth/composables';
import TopBanners from 'web/src/modules/banners/components/TopBanners/TopBanners.vue';
import SnackbarHolder from 'web/src/modules/snackbars/components/SnackbarHolder/SnackbarHolder.vue';
import TheHeader from '../TheHeader/TheHeader.vue';
import { useKeepAliveComponents, usePostponedContentLoad } from '../composables';
import { useLayoutDesktopDefault } from './composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'LayoutDesktopDefault',
    setup (__props) {
        const TheBetSlip = defineAsyncComponent(()=>import('web/src/modules/slip/views/TheBetsSlipRootView.vue'));
        // eslint-disable-next-line max-len
        const AuthorizedSnackbars = defineAsyncComponent(()=>import('web/src/modules/core/components/AuthorizedSnackbars/AuthorizedSnackbars.vue'));
        const LayoutDesktopSidebar = defineAsyncComponent(()=>import('web/src/modules/core/components/LayoutDesktopLLeftSidebar/LayoutDesktopLLeftSidebar.vue'));
        const { isLoggedIn } = useIsLoggedIn();
        const { keepAliveIncludes } = useKeepAliveComponents();
        const { content, hasFixedBar, hasLeftSideBar, hasRightSideBar, isFullWidth, isStoryBanner, isEgsLobby, isFooterVisible, isBannerVisible } = useLayoutDesktopDefault();
        const { isMainContentLoaded, loadMainContent } = usePostponedContentLoad();
        return (_ctx, _cache)=>{
            const _component_router_view = _resolveComponent("router-view");
            const _directive_data_test = _resolveDirective("data-test");
            return _openBlock(), _createElementBlock(_Fragment, null, [
                _createVNode(TheHeader, null, {
                    default: _withCtx(()=>[
                            _createVNode(HeaderBarRouteComponent)
                        ]),
                    _: 1
                }),
                _createElementVNode("section", {
                    class: _normalizeClass(_ctx.$style['content-wrap'])
                }, [
                    _createElementVNode("div", {
                        class: _normalizeClass(_ctx.$style['column'])
                    }, [
                        _unref(isMainContentLoaded) && _unref(hasLeftSideBar) ? _withDirectives((_openBlock(), _createBlock(_unref(LayoutDesktopSidebar), {
                            key: 0
                        }, null, 512)), [
                            [
                                _unref(vDataTestUnit),
                                {
                                    el: 'left-sidebar'
                                }
                            ]
                        ]) : _createCommentVNode("", true),
                        _createElementVNode("main", {
                            class: _normalizeClass(_ctx.$style['column__main'])
                        }, [
                            _unref(hasFixedBar) ? _withDirectives((_openBlock(), _createElementBlock("div", {
                                key: 0,
                                class: _normalizeClass(_ctx.$style['fixed-bar'])
                            }, [
                                _createElementVNode("div", {
                                    class: _normalizeClass(_ctx.$style['fixed-bar__content'])
                                }, [
                                    _createVNode(_component_router_view, {
                                        name: "fixedBar"
                                    })
                                ], 2)
                            ], 2)), [
                                [
                                    _unref(vDataTestUnit),
                                    {
                                        el: 'fixed-bar'
                                    }
                                ]
                            ]) : _createCommentVNode("", true),
                            _createElementVNode("div", {
                                id: "js__content-scroll",
                                ref_key: "content",
                                ref: content,
                                class: _normalizeClass(_ctx.$style['content']),
                                onVnodeMounted: _cache[0] || (_cache[0] = //@ts-ignore
                                function() {
                                    for(var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++){
                                        args[_key] = arguments[_key];
                                    }
                                    return _unref(loadMainContent) && _unref(loadMainContent)(...args);
                                })
                            }, [
                                _createVNode(_component_router_view, {
                                    name: "contentLoader"
                                }),
                                _unref(isLoggedIn) ? (_openBlock(), _createBlock(_component_router_view, {
                                    key: 0,
                                    name: "topAuthorizedSlot"
                                })) : _createCommentVNode("", true),
                                _unref(isBannerVisible) ? (_openBlock(), _createElementBlock("div", {
                                    key: 1,
                                    class: _normalizeClass({
                                        [_ctx.$style['content-min-max']]: _unref(isFullWidth)
                                    })
                                }, [
                                    (_openBlock(), _createBlock(_unref(LazyMainBannerSectionRouteComponent), {
                                        key: 1,
                                        "is-story-banner": _unref(isStoryBanner),
                                        "is-big-height": _unref(isEgsLobby)
                                    }, null, 8, [
                                        "is-story-banner",
                                        "is-big-height"
                                    ]))
                                ], 2)) : _createCommentVNode("", true),
                                _createVNode(_component_router_view, {
                                    name: "navigation"
                                }),
                                _createVNode(_component_router_view, {
                                    name: "title"
                                }),
                                _withDirectives((_openBlock(), _createElementBlock("div", {
                                    class: _normalizeClass([
                                        _ctx.$style['main-component'],
                                        'popper-content-target'
                                    ])
                                }, [
                                    _createVNode(_component_router_view, null, {
                                        default: _withCtx((param)=>{
                                            let { Component } = param;
                                            return [
                                                (_openBlock(), _createBlock(_KeepAlive, {
                                                    include: _unref(keepAliveIncludes)
                                                }, [
                                                    (_openBlock(), _createBlock(_resolveDynamicComponent(Component)))
                                                ], 1032, [
                                                    "include"
                                                ])),
                                                _unref(isFooterVisible) ? (_openBlock(), _createBlock(FooterRouteComponent, {
                                                    key: 0,
                                                    "is-full-width": !_unref(hasLeftSideBar),
                                                    class: _normalizeClass(_ctx.$style['footer'])
                                                }, null, 8, [
                                                    "is-full-width",
                                                    "class"
                                                ])) : _createCommentVNode("", true)
                                            ];
                                        }),
                                        _: 1
                                    })
                                ], 2)), [
                                    [
                                        _directive_data_test,
                                        {
                                            el: 'component-wrapper-height-holder'
                                        }
                                    ]
                                ])
                            ], 2)
                        ], 2),
                        _unref(hasRightSideBar) && _unref(isMainContentLoaded) ? _withDirectives((_openBlock(), _createElementBlock("aside", {
                            key: 1,
                            "aria-label": "right-sidebar",
                            class: _normalizeClass(_ctx.$style['column__right'])
                        }, [
                            _createVNode(_unref(TheBetSlip))
                        ], 2)), [
                            [
                                _unref(vDataTestUnit),
                                {
                                    el: 'right-sidebar'
                                }
                            ]
                        ]) : _createCommentVNode("", true)
                    ], 2)
                ], 2),
                _unref(isLoggedIn) ? (_openBlock(), _createBlock(_unref(AuthorizedSnackbars), {
                    key: 0
                })) : _createCommentVNode("", true),
                _createVNode(SnackbarHolder),
                (_openBlock(), _createBlock(_Teleport, {
                    to: "body"
                }, [
                    _createVNode(DesktopModal)
                ]))
            ], 64);
        };
    }
});
