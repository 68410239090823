import { useInitialRequests } from 'web/src/modules/core/components/composables';
import fetchRegistrationForms from 'web/src/modules/registration/utils/fetchRegistrationForms';
export default function useGuestHandler() {
    const { getInitRequests } = useInitialRequests();
    async function onLogout(isInit) {
        const requests = [];
        if (isInit) requests.push(...getInitRequests());
        "1";
        requests.push(fetchRegistrationForms());
        await Promise.all(requests);
    }
    return {
        onLogout
    };
}
