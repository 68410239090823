import { computed, toRef } from 'vue';
import noop from 'lodash/noop';
import { ScriptElement } from '@leon-hub/utils';
import { onComponentActivated, onComponentDeactivated } from '@leon-hub/vue-utils';
import { whenClientNetworkIdle } from '@leon-hub/idle';
import { useSiteConfigStore } from 'web/src/modules/core/store';
import { useTheme } from 'web/src/modules/theme/composables';
const landingResizeCoefficient = 1.25;
export default function useFooterLicense(props) {
    const { isDark } = useTheme();
    const scgStore = useSiteConfigStore();
    const licenseBlock = toRef(scgStore, 'licenseBlock');
    const footerLogotypesBlock = toRef(scgStore, 'footerLogotypesBlock');
    const isFooterLogotypesEnabled = toRef(scgStore, 'isFooterLogotypesEnabled');
    const isKwkLogoEnabled = computed(()=>licenseBlock.value?.kwkIconEnabled);
    const kwkUrl = computed(()=>licenseBlock.value?.kwkUrl);
    const isCrcLogoEnabled = computed(()=>licenseBlock.value?.crcIconEnabled);
    const crcUrl = computed(()=>licenseBlock.value?.crcUrl);
    const isCrcEgamingLogoEnabled = computed(()=>licenseBlock.value?.crcEgamingIconEnabled);
    const crcEgamingUrl = computed(()=>licenseBlock.value?.crcEgamingUrl);
    const crcEgamingLogoUrl = computed(()=>licenseBlock.value?.crcEgamingLogoUrl);
    const isAnjouanLBZLogoEnabled = 'leonbz.bet' === document.location.host;
    const isAnjouanTBZLogoEnabled = 'twinbz.bet' === document.location.host;
    const doShowGenericLogos = computed(()=>isFooterLogotypesEnabled.value);
    const newLogos = computed(()=>footerLogotypesBlock.value?.footerLogotypesByLocale ?? []);
    const legacyLogos = computed(()=>footerLogotypesBlock.value?.footerLogotypes ?? []);
    const logos = computed(()=>newLogos.value.length > 0 ? newLogos.value : legacyLogos.value);
    const doShowLogos = computed(()=>doShowGenericLogos.value && logos.value.length > 0 || !!isKwkLogoEnabled.value || !!isCrcLogoEnabled.value || !!isAnjouanLBZLogoEnabled || !!isAnjouanTBZLogoEnabled);
    function dynamicSize(size) {
        if (!size) return;
        return props.isLanding ? size * landingResizeCoefficient : size;
    }
    function getLogo(logo) {
        return isDark.value ? logo.iconDark : logo.iconLight;
    }
    let licenseScript;
    onComponentActivated(()=>{
        if (crcUrl.value) {
            const crcValue = crcUrl.value;
            whenClientNetworkIdle({
                interval: 300
            }).then(()=>{
                licenseScript = new ScriptElement({
                    src: crcValue
                });
                licenseScript.addScript().catch(noop);
            });
        }
        // do not log script load errors
        if (isAnjouanLBZLogoEnabled) {
            licenseScript = new ScriptElement({
                src: 'https://ffa489c6-d6eb-41e7-9e0f-ab25ac2dd6f5.snippet.anjouangaming.org/anj-seal.js'
            });
            licenseScript.addScript().catch(noop);
        }
        // do not log script load errors
        if (isAnjouanTBZLogoEnabled) {
            licenseScript = new ScriptElement({
                src: 'https://7ad78d18-350b-4099-bf1e-6ba20df24adf.snippet.anjouangaming.org/anj-seal.js'
            });
            licenseScript.addScript().catch(noop);
        }
    });
    // do not log script load errors
    onComponentDeactivated(()=>{
        licenseScript?.removeScript();
        licenseScript = void 0;
    });
    return {
        doShowLogos,
        doShowGenericLogos,
        isKwkLogoEnabled,
        isCrcLogoEnabled,
        isCrcEgamingLogoEnabled,
        kwkUrl,
        crcUrl,
        crcEgamingUrl,
        crcEgamingLogoUrl,
        isAnjouanLBZLogoEnabled,
        isAnjouanTBZLogoEnabled,
        logos,
        getLogo,
        dynamicSize
    };
}
