import { CaptchaStatus, CaptchaType, RecaptchaTheme } from '@leon-hub/api-sdk';
export default function getDefaultCaptchaConfig() {
    return {
        loginCaptchaStatus: CaptchaStatus.OFF,
        passwordRecoveryCaptchaStatus: CaptchaStatus.OFF,
        registrationCaptchaStatus: CaptchaStatus.OFF,
        loginCaptchaType: CaptchaType.RECAPTCHA,
        passwordRecoveryCaptchaType: CaptchaType.RECAPTCHA,
        registrationCaptchaType: CaptchaType.RECAPTCHA,
        captchaSecuritySignEnabled: false,
        useIframeForCaptcha: false,
        captchaApiUrl: '',
        captchaIframeUrl: '',
        recaptcha: {
            theme: RecaptchaTheme.LIGHT,
            siteKeys: []
        },
        recaptchaV3: {
            loginEnabled: false,
            passwordRecoveryEnabled: false,
            registrationEnabled: false,
            siteKey: void 0,
            timeout: 10000
        }
    };
}
