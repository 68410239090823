// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.file-input-photo-placeholder_uKWk4{position:relative;display:flex;justify-content:center;height:100%;overflow:hidden;border-radius:5px}.file-input-photo-placeholder__hovered_sVfz3:hover .file-input-photo-placeholder__preview-button_t6glR{display:block}.file-input-photo-placeholder_uKWk4 .file-input-photo-placeholder__preview-button_t6glR,.file-input-photo-placeholder__hovered_sVfz3:hover .file-input-photo-placeholder__icon_Kixgg{display:none}.file-input-photo-placeholder_uKWk4 .file-input-photo-placeholder__icon_Kixgg{display:block}.file-input-photo-placeholder__error_F8BVA{border:2px solid;border-color:var(--ErrorDefault)}.file-input-photo-placeholder__frame_nx_CS{object-fit:contain}.file-input-photo-placeholder__controls_Nk5UQ{position:absolute;top:0;left:0;display:flex;flex-direction:column;align-items:center;justify-content:flex-start;width:100%;height:100%;border-radius:5px}.file-input-photo-placeholder__controls--obscured_TWULM{background-color:var(--OpacityLayer0)}.file-input-photo-placeholder__icon-wrapper_ZoPpP{margin:32px 0 16px;color:var(--BrandDefault)}.file-input-photo-placeholder__icon-wrapper--error_TXZyT{color:var(--ErrorText)}.file-input-photo-placeholder__file-name_s5fVB{font-size:12px;font-weight:400;line-height:16px;letter-spacing:normal;margin-bottom:24px;color:var(--TextDefault)}.file-input-photo-placeholder__error-title_KaQdq{font-size:12px;font-weight:500;line-height:16px;letter-spacing:normal;margin-bottom:2px;color:var(--TextDefault)}.file-input-photo-placeholder__error-file-name_Bbr5H{font-size:12px;font-weight:400;line-height:16px;letter-spacing:normal;margin-bottom:2px;color:var(--TextPrimary)}.file-input-photo-placeholder__clear_MUTT3{font-size:13px;font-weight:400;line-height:20px;letter-spacing:normal;color:var(--ErrorText);cursor:pointer;background:none;border:none}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"file-input-photo-placeholder": `file-input-photo-placeholder_uKWk4`,
	"file-input-photo-placeholder__hovered": `file-input-photo-placeholder__hovered_sVfz3`,
	"file-input-photo-placeholder__preview-button": `file-input-photo-placeholder__preview-button_t6glR`,
	"file-input-photo-placeholder__icon": `file-input-photo-placeholder__icon_Kixgg`,
	"file-input-photo-placeholder__error": `file-input-photo-placeholder__error_F8BVA`,
	"file-input-photo-placeholder__frame": `file-input-photo-placeholder__frame_nx_CS`,
	"file-input-photo-placeholder__controls": `file-input-photo-placeholder__controls_Nk5UQ`,
	"file-input-photo-placeholder__controls--obscured": `file-input-photo-placeholder__controls--obscured_TWULM`,
	"file-input-photo-placeholder__icon-wrapper": `file-input-photo-placeholder__icon-wrapper_ZoPpP`,
	"file-input-photo-placeholder__icon-wrapper--error": `file-input-photo-placeholder__icon-wrapper--error_TXZyT`,
	"file-input-photo-placeholder__file-name": `file-input-photo-placeholder__file-name_s5fVB`,
	"file-input-photo-placeholder__error-title": `file-input-photo-placeholder__error-title_KaQdq`,
	"file-input-photo-placeholder__error-file-name": `file-input-photo-placeholder__error-file-name_Bbr5H`,
	"file-input-photo-placeholder__clear": `file-input-photo-placeholder__clear_MUTT3`
};
export default ___CSS_LOADER_EXPORT___;
